import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { Container } from '../components/Container'
import {
    TwitterIcon,
    InstagramIcon,
    GitHubIcon,
    LinkedInIcon,
    MailIcon,
    // PhoneIcon,
} from '../components/SocialIcons'
import portraitImage from '../static/images/portrait.jpg'
import SEO from './../components/SEO'

function SocialLink({ className, href, children, icon: Icon }) {
    return (
        <li className={clsx(className, 'flex')}>
            <Link
                to={href}
                className="group flex text-sm font-medium text-zinc-800 transition hover:text-purple-500 dark:text-zinc-200 dark:hover:text-purple-500"
            >
                <Icon className="h-6 w-6 flex-none fill-zinc-500 transition group-hover:fill-purple-500" />
                <span className="ml-4">{children}</span>
            </Link>
        </li>
    )
}

export default function AboutPage() {
    return (
        <>
            <SEO
                title='About | TheAdeyemiOlayinka'
                description='I am Olayinka Adeyemi, a professional Fullstack Web and Mobile developer based in Lagos, Nigeria.'
                name='TheAdeyemiOlayinka.'
                type='profile' />
            <Container className="mt-16 sm:mt-32">
                <div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                    <div className="lg:pl-20">
                        <div className="max-w-xs px-2.5 lg:max-w-none">
                            <img
                                src={portraitImage}
                                alt=""
                                sizes="(min-width: 1024px) 32rem, 20rem"
                                className="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800"
                            />
                        </div>
                    </div>
                    <div className="lg:order-first lg:row-span-2">
                        <h1 className="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                            I'm Olayinka Adeyemi. I live in Lagos, Nigeria - where I build the future.
                        </h1>
                        <div className='tao-typed-strings-el display-none hidden'>
                            <div>
                                <p>
                                    Greetings! I'm Olayinka Adeyemi, a Senior Fullstack Developer with over six years of experience in building innovative, scalable, and efficient software solutions. My journey into technology began as a curious enthusiast, captivated by the endless possibilities of programming. Over time, I’ve refined my skills and transformed that curiosity and passion into a fulfilling career.
                                </p>
                        
                                <p>
                                    I specialize in creating seamless digital experiences using technologies like Laravel, Nest.js, React.js, Next.js, Flutter, and Django. Whether it’s architecting robust back-end systems or designing intuitive front-end interfaces or even managing deployment and server resources, I excel at solving complex problems and delivering high-quality solutions that meet user needs and business goals. From concept to deployment, I ensure every project is crafted with precision and care.
                                </p>
                        
                                <p>
                                    Beyond technical expertise, I take pride in my leadership abilities, mentoring teams, fostering collaboration, and driving projects to success. My tenacity and attention to detail have been instrumental in overcoming challenges and achieving exceptional results. I am committed to continuous learning and staying ahead in the fast-paced tech industry.
                                </p>
                        
                                <p>
                                    While I’m passionate about technology, my journey is heavily shaped by my faith as a devout Christian, which inspires my principles and approach to both work and life. Outside of development, I enjoy exploring the physics and engineering that govern our world, drawing inspiration from the intersection of creativity and science.
                                </p>
                        
                                <p>
                                    In essence, I’m not just a developer—I’m a problem solver, a leader, and an innovator. Thank you for visiting my portfolio! Let’s connect and create something extraordinary together!
                                </p>
                            </div>
                        </div>

                        <div className="tao-typed-target mt-6 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                        </div>
                    </div>
                    <div className="lg:pl-20">
                        <ul>
                            <SocialLink href="https://twitter.com/the_sage7" icon={TwitterIcon}>
                                Follow on Twitter
                            </SocialLink>
                            <SocialLink href="https://instagram.com/theadeyemiolayinka" icon={InstagramIcon} className="mt-4">
                                Follow on Instagram
                            </SocialLink>
                            <SocialLink href="https://github.com/theadeyemiolayinka" icon={GitHubIcon} className="mt-4">
                                Follow on GitHub
                            </SocialLink>
                            <SocialLink href="https://linkedin.com/in/theadeyemiolayinka" icon={LinkedInIcon} className="mt-4">
                                Follow on LinkedIn
                            </SocialLink>
                            <SocialLink
                                href="mailto:hi@theadeyemiolayinka.com"
                                icon={MailIcon}
                                className="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40"
                            >
                                hi@theadeyemiolayinka.com
                            </SocialLink>
                        </ul>
                    </div>
                </div>
            </Container>
        </>
    )
}
